<template>
  <div class="about">

    <v-card
        class="mx-auto my-5 "
        max-width="400"
    >
      <v-img
          src="@/assets/new_agre.jpg"
          height="400px"
      ></v-img>

<!--      <v-card-title>-->
<!--        Новогодняя пауза-->
<!--      </v-card-title>-->

      <v-card-title>
        Закрытие продаж
      </v-card-title>

<!--      <v-card-subtitle>-->
<!--        По техническим причинам онлайн продажа билетов возобновиться с 15 января 2023 года-->
<!--      </v-card-subtitle>-->

      <v-card-subtitle>
        Уважаемые родители участников клубных формирований!
        <br><br>
        С 01.07.2024 по 30.09.2024 года онлайн оплата за кружки будет закрыта.
        <br>
        С 1 октября 2024 года оплата занятий онлайн будет доступна только после заключения договора.
        <br><br>
        До встречи в новом творческом сезоне!
      </v-card-subtitle>

      <v-card-actions>
<!--        <v-btn-->
<!--            color="orange lighten-2"-->
<!--            text-->
<!--            @click="gotoMore()"-->
<!--        >-->
<!--          Подробнее-->
<!--        </v-btn>-->

        <v-spacer></v-spacer>

      </v-card-actions>

    </v-card>


  </div>
</template>

<script>



export default {
  name: 'ServiceStop',

  components: {

  },

  methods: {

    gotoMore(){
      window.location.href = "http://tkk-dk.ru/2022/12/09/%d0%be%d0%bf%d0%bb%d0%b0%d1%82%d0%b0-%d0%b1%d0%b8%d0%bb%d0%b5%d1%82%d0%be%d0%b2-%d1%87%d0%b5%d1%80%d0%b5%d0%b7-%d0%be%d0%bd%d0%bb%d0%b0%d0%b9%d0%bd-%d0%b1%d1%83%d0%b4%d0%b5%d1%82-%d0%be%d1%81%d1%82/";

    }

  },
};
</script>
